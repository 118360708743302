import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import downloadArrow from "../../assets/img/download-arrow.png";
import ReactToPdf from "react-to-pdf";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar";
import { Button, CardContent, Skeleton } from "@mui/material";
import ChartArea from "../../components/ChartArea";
import {
  averageSmileIcon,
  badSmileIcon,
  goodSmileIcon,
  veryBadSmileIcon,
  veryGoodSmileIcon,
} from "../../assets";
import {
  getAiPerformanceRate,
  getAverageMessagesPerSession,
  getAverageSessionDuration,
  getBotResponseTime,
  getEscalationRate,
  getRetentionRate,
  getUserFeedbackRate,
} from "../../slices/analytics";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
function Statistics() {
  const { t } = useTranslation();
  moment.locale(localStorage.getItem("i18nextLng"));
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [13, 15],
  };
  const {
    averageSessionDuration,
    totalAverageDuration,
    statusAverageSessionDuration,
    averageMessagesPerSession,
    totalAverageMessages,
    statusAverageMessagesPerSession,
    retentionRate,
    totalRetentionRate,
    statusRetentionRate,
    statusAiPerformanceRate,
    aiPerformanceRate,
    totalAiSuccessRate,
    totalAiFailureRate,
    statusEscalationRate,
    escalationRate,
    totalEscalationRate,
    usersFeedbackRate,
    statusUsersfeedbackRate,
    averageUsersFeedbackPercentage,
    averageBotResponseTime,
    statusBotResponseTime,
    totalAverageBotResponseTime,
  } = useSelector((state) => state.analytics);

  const ratings = {
    veryGoodRate: usersFeedbackRate?.find((el) => el?.rating === 5)
      ? usersFeedbackRate?.find((el) => el?.rating === 5)?.percentage
      : 0,
    goodRate: usersFeedbackRate?.find((el) => el?.rating === 4)
      ? usersFeedbackRate?.find((el) => el?.rating === 4)?.percentage
      : 0,
    averageRate: usersFeedbackRate?.find((el) => el?.rating === 3)
      ? usersFeedbackRate?.find((el) => el?.rating === 3)?.percentage
      : 0,
    badRate: usersFeedbackRate?.find((el) => el?.rating === 2)
      ? usersFeedbackRate?.find((el) => el?.rating === 2)?.percentage
      : 0,
    veryBadRate: usersFeedbackRate?.find((el) => el?.rating === 1)
      ? usersFeedbackRate?.find((el) => el?.rating === 1)?.percentage
      : 0,
  };

  const [progressPercent, setProgressPercent] = useState({
    veryGoodRate: 0,
    goodRate: 0,
    averageRate: 0,
    badRate: 0,
    veryBadRate: 0,
  });

  useEffect(() => {
    // Calculate the progress percentage based on data changes
    const newProgressPercent = {
      veryGoodRate: ratings.veryGoodRate,
      goodRate: ratings.goodRate,
      averageRate: ratings.averageRate,
      badRate: ratings.badRate,
      veryBadRate: ratings.veryBadRate,
    };
    //const newProgressPercent = 100;
    setProgressPercent(newProgressPercent);
  }, [usersFeedbackRate]);

  useEffect(() => {
    statusUsersfeedbackRate === "success" &&
      // Move the progress bar whenever the progress percentage changes
      moveProgressBar();

    // Add a window resize event listener to handle progress bar on resize
    window.addEventListener("resize", moveProgressBar);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", moveProgressBar);
    };
  }, [progressPercent, statusUsersfeedbackRate]);

  // SIGNATURE PROGRESS
  const moveProgressBar = () => {
    Object.entries(progressPercent).forEach((el, index) => {
      const getPercent = progressPercent[el[0]] / 100;
      const getProgressWrapWidth = document.querySelector(
        `.progress-wrap-${index + 1}`
      ).clientWidth;
      const progressTotal = getPercent * getProgressWrapWidth;
      const animationLength = 1000;

      // Animate the progress bar
      document.querySelector(
        `.progress-bar-${index + 1}`
      ).style.transition = `left ${animationLength}ms ease-in-out`;
      document.querySelector(
        `.progress-bar-${index + 1}`
      ).style.left = `${progressTotal}px`;
    });
  };

  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(new Date());

  useEffect(() => {
    !Array.isArray(user) &&
      (dispatch(
        getAverageSessionDuration({ websiteID: user?.websiteID, year, month })
      ),
      dispatch(
        getAverageMessagesPerSession({
          websiteID: user?.websiteID,
          year,
          month,
        })
      ),
      dispatch(getRetentionRate({ websiteID: user?.websiteID, year, month })),
      dispatch(
        getAiPerformanceRate({ websiteID: user?.websiteID, year, month })
      ),
      dispatch(getEscalationRate({ websiteID: user?.websiteID, year, month })),
      dispatch(
        getUserFeedbackRate({ websiteID: user?.websiteID, year, month })
      ).then(
        (res) =>
          !res?.error &&
          (() => {
            // on page load...
            moveProgressBar();
          })()
      ),
      dispatch(
        getBotResponseTime({ websiteID: user?.websiteID, year, month })
      ));
  }, [user, year, month]);

  return (
    <div className="analytics-container">
      <div className="title--analytics--page">
        <div>
          <p className="analytics--p">{t("Analytics").replace(" ", "\xa0")}</p>
        </div>
        <div className="btns-container-analytics">
          <div>
            {" "}
            <Button
              disabled={month === null}
              onClick={() => setMonth(null)}
              className="reset-month-btn"
            >
              Reset month
            </Button>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <DatePicker
                  views={["month"]}
                  disableFuture
                  slotProps={{
                    calendarHeader: {
                      sx: { display: "none" },
                    },
                  }}
                  value={month}
                  onChange={(newValue) => setMonth(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  views={["year"]}
                  disableFuture
                  minDate={new Date("2024")}
                  value={year}
                  onChange={(newValue) => setYear(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </LocalizationProvider>
          </div>

          <ReactToPdf
            targetRef={ref}
            filename="div-blue.pdf"
            options={options}
            x={1}
            y={1}
            scale={0.7}
          >
            {({ toPdf }) => (
              <div onClick={toPdf} className="download-btn">
                <div>
                  <LazyLoadImage
                    src={downloadArrow}
                    alt="arrow"
                    className="download-arrow"
                  />
                </div>

                <button className="btn-report">{t("Download Report")}</button>
              </div>
            )}
          </ReactToPdf>
        </div>
      </div>
      <div ref={ref}>
        {" "}
        <div className="stat-wrapper">
          <div
            style={{
              display: "flex",
              width: "100%",
              gap:
                (["idle", "loading"].includes(
                  statusAverageMessagesPerSession
                ) ||
                  ["idle", "loading"].includes(statusAverageSessionDuration)) &&
                "15px",
              padding:
                (["idle", "loading"].includes(
                  statusAverageMessagesPerSession
                ) ||
                  ["idle", "loading"].includes(statusAverageSessionDuration)) &&
                "0 12px",
            }}
          >
            {["idle", "loading"].includes(statusAverageMessagesPerSession) ? (
              <Skeleton
                width={"49%"}
                sx={{ minHeight: "400px", height: "100%" }}
              />
            ) : (
              <div className="stat-container">
                <div>
                  <h2 className="stat-title">
                    Nombre moyen de messages par session :{" "}
                    <span>{totalAverageMessages}</span>
                  </h2>
                </div>
                <CardContent style={{ padding: 0 }}>
                  <ChartArea
                    month={month}
                    averageMessagesPerSession={averageMessagesPerSession}
                  />
                </CardContent>
              </div>
            )}

            {["idle", "loading"].includes(statusAverageSessionDuration) ? (
              <Skeleton
                width={"49%"}
                sx={{ minHeight: "400px", height: "100%" }}
              />
            ) : (
              <div className="stat-container">
                <div>
                  <h2 className="stat-title">
                    Durée moyenne par session:{" "}
                    <span>{totalAverageDuration / 60} mins</span>
                  </h2>
                </div>
                <CardContent style={{ padding: 0 }}>
                  <ChartArea
                    averageSessionDuration={averageSessionDuration}
                    month={month}
                  />
                </CardContent>
              </div>
            )}
          </div>

          {["idle", "loading"].includes(statusAiPerformanceRate) ? (
            <div style={{ padding: "0 12px", width: "100%" }}>
              <Skeleton
                width={"100%"}
                sx={{ minHeight: "400px", height: "100%" }}
              />
            </div>
          ) : (
            <>
              <div className="stat-container">
                <div>
                  <h2 className="stat-title">
                    Taux de réussite de performance de l'IA:{" "}
                    <span>{totalAiSuccessRate}%</span>
                  </h2>
                </div>
                <CardContent style={{ padding: 0 }}>
                  <ChartArea
                    month={month}
                    aiSuccessPerformanceRate={aiPerformanceRate}
                  />
                </CardContent>
              </div>
              <div className="stat-container">
                <div>
                  <h2 className="stat-title">
                    Taux d'échec des performances de l'IA :{" "}
                    <span>{totalAiFailureRate}%</span>
                  </h2>
                </div>
                <CardContent style={{ padding: 0 }}>
                  <ChartArea
                    month={month}
                    aiFailurePerformanceRate={aiPerformanceRate}
                  />
                </CardContent>
              </div>
            </>
          )}
        </div>
        {["idle", "loading"].includes(statusUsersfeedbackRate) ? (
          <div style={{ width: "100%", padding: "0 12px" }}>
            <Skeleton
              width={"100%"}
              sx={{ minHeight: "400px", height: "100%" }}
            />
          </div>
        ) : (
          <div className="stat-emojis">
            <div className="stat-emojis-title-container">
              <h2 className="stat-emojis-title">
                Taux de satisfaction utilisateur <br />
              </h2>
              <span>{averageUsersFeedbackPercentage}%</span> 
            </div>
            <div className="stat-specific-emojis-wrapper">
              <div className="stat-progress">
                <div className="stat-specific-emoji">
                  <div>
                    <LazyLoadImage src={veryGoodSmileIcon} />
                    <span>Very good</span>
                  </div>
                  <span className="stat-emoji-percentage">
                    {ratings.veryGoodRate}%
                  </span>
                </div>

                <div
                  className="progress-wrap-1 progress"
                  data-progress-percent={(ratings.veryGoodRate / 5) * 100}
                >
                  <div class="progress-bar-1 progress"></div>
                </div>
              </div>

              <div className="stat-progress">
                <div className="stat-specific-emoji">
                  <div>
                    <LazyLoadImage src={goodSmileIcon} />
                    <span>Good</span>
                  </div>
                  <span className="stat-emoji-percentage">
                    {ratings.goodRate}%
                  </span>
                </div>

                <div
                  className="progress-wrap-2 progress"
                  data-progress-percent={(ratings.goodRate / 5) * 100}
                >
                  <div class="progress-bar-2 progress"></div>
                </div>
              </div>
              <div className="stat-progress">
                <div className="stat-specific-emoji">
                  <div>
                    <LazyLoadImage src={averageSmileIcon} />
                    <span>Average</span>
                  </div>
                  <span className="stat-emoji-percentage">
                    {ratings.averageRate}%
                  </span>
                </div>

                <div
                  className="progress-wrap-3 progress"
                  data-progress-percent={(ratings.averageRate / 5) * 100}
                >
                  <div class="progress-bar-3 progress"></div>
                </div>
              </div>
              <div className="stat-progress">
                <div className="stat-specific-emoji">
                  <div>
                    <LazyLoadImage src={badSmileIcon} />
                    <span>Bad</span>
                  </div>
                  <span className="stat-emoji-percentage">
                    {ratings.badRate}%
                  </span>
                </div>

                <div
                  className="progress-wrap-4 progress"
                  data-progress-percent={(ratings.badRate / 5) * 100}
                >
                  <div class="progress-bar-4 progress"></div>
                </div>
              </div>
              <div className="stat-progress">
                <div className="stat-specific-emoji">
                  <div>
                    <LazyLoadImage src={veryBadSmileIcon} />
                    <span>Very bad</span>
                  </div>
                  <span className="stat-emoji-percentage">
                    {ratings.veryBadRate}%
                  </span>
                </div>

                <div
                  className="progress-wrap-5 progress"
                  data-progress-percent={(ratings.veryBadRate / 5) * 100}
                >
                  <div class="progress-bar-5 progress"></div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="stat-wrapper">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {" "}
            {["idle", "loading"].includes(statusRetentionRate) ? (
              <Skeleton
                width={"49%"}
                sx={{ minHeight: "400px", height: "100%" }}
              />
            ) : (
              <div className="stat-container">
                <div>
                  <h2 className="stat-title" style={{ paddingLeft: 0 }}>
                    Taux de rétention
                  </h2>
                </div>
                <div className="stat-advanced-wrapper">
                  <div className="stat-advanced-description">
                    <span>{totalRetentionRate} sec</span>
                  </div>
                  <CardContent style={{ padding: 0, flex: 1 }}>
                    <ChartArea retentionRate={retentionRate} month={month} />
                  </CardContent>
                </div>
              </div>
            )}
            {["idle", "loading"].includes(statusEscalationRate) ? (
              <Skeleton width={"49%"} />
            ) : (
              <div className="stat-container">
                <div>
                  <h2 className="stat-title" style={{ paddingLeft: 0 }}>
                    Taux d'escalade
                  </h2>
                </div>
                <div className="stat-advanced-wrapper">
                  <div className="stat-advanced-description">
                    <span>{totalEscalationRate}%</span>
                  </div>
                  <CardContent style={{ padding: 0, flex: 1 }}>
                    <ChartArea month={month} escalationRate={escalationRate} />
                  </CardContent>
                </div>
              </div>
            )}
          </div>

          {["idle", "loading"].includes(statusBotResponseTime) ? (
            <div style={{ width: "100%", padding: "0 12px" }}>
              <Skeleton
                width={"100%"}
                sx={{ minHeight: "400px", height: "100%" }}
              />{" "}
            </div>
          ) : (
            <div className="stat-container" style={{ width: "100%" }}>
              <div>
                <h2 className="stat-title" style={{ paddingLeft: 0 }}>
                  Temps de réponse moyen du robot
                </h2>
              </div>
              <div className="stat-advanced-wrapper">
                <div className="stat-advanced-description">
                  <span>{totalAverageBotResponseTime} sec</span>
                </div>
                <CardContent style={{ padding: 0, flex: 1 }}>
                  <ChartArea
                    averageBotResponseTime={averageBotResponseTime}
                    month={month}
                  />
                </CardContent>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Statistics;
